import { ReactNode } from 'react';

import BlockUi from '@availity/block-ui';

import Spinner from '@components/spinner/Spinner';

const ScreenBlockUi = ({
  children,
  isBlocking,
}: {
  children: ReactNode;
  isBlocking: boolean;
}) => {
  return (
    <BlockUi
      tag="div"
      className="z-10 h-screen items-center relative"
      blocking={isBlocking}
      loader={
        <div className="absolute inset-0 flex items-center justify-center z-20 backdrop-blur-[1px]">
          <Spinner isLarge />
        </div>
      }
      message={null}
      renderChildren={true}
    >
      {children}
    </BlockUi>
  );
};

export default ScreenBlockUi;
