import { FC, ReactNode, useCallback } from 'react';

import { useDropzone } from 'react-dropzone';

interface DropzoneProps {
  accept?: string;
  children?: ReactNode;
  maxSize?: number;
  multiple?: boolean;
  onFilesReject?: any;
  onFilesDrop?(files: File[]): void;
}

const Dropzone: FC<DropzoneProps> = ({
  accept = '',
  children,
  maxSize,
  multiple = true,
  onFilesDrop = () => null,
  onFilesReject = () => null,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => onFilesDrop(acceptedFiles),
    [onFilesDrop],
  );
  const { getRootProps, getInputProps } = useDropzone({
    accept,
    multiple,
    onDrop,
    ...(onFilesReject && { onDropRejected: onFilesReject }),
    ...(maxSize && { maxSize }),
    noDragEventsBubbling: true,
  });

  return (
    <div {...getRootProps()}>
      {children}
      <input {...getInputProps()} />
    </div>
  );
};

export default Dropzone;
