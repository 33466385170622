import { ListboxButton } from '@headlessui/react';

import { AngleDown, AngleUp } from '@assets/icons';

const SelectTypeRefComponent = ({
  label,
  isOpen,
  placeHolder,
}: {
  label: string;
  isOpen: boolean;
  placeHolder?: string;
}) => {
  const AngleIcon = isOpen ? AngleDown : AngleUp;

  return (
    <ListboxButton className="w-full cursor-pointer">
      <div className="w-full h-10 flex justify-between items-center px-2 border border-gray-4 rounded-md">
        <div className="flex justify-start items-center truncate font-semibold whitespace-nowrap">
          {label ? (
            <span className="truncate pl-2">{label}</span>
          ) : (
            <div className="text-gray-4 text-sm">{placeHolder}</div>
          )}
        </div>
        <AngleIcon className="text-gray-1" />
      </div>
    </ListboxButton>
  );
};

export default SelectTypeRefComponent;
