import { useRecoilState } from 'recoil';

import { UploadFiles, uploadFiles } from '@store/atoms/upload-files';

export const useUploadProgress = () => {
  const [fileList, setFileList] = useRecoilState(uploadFiles);

  const upsert = (data: UploadFiles) => {
    setFileList(currentState => {
      const index = currentState.findIndex(item => item.name === data.name);

      if (index >= 0) {
        const updatedItems = [...currentState];
        updatedItems[index] = data;

        return updatedItems;
      }

      return [...currentState, data];
    });
  };

  const remove = (data: UploadFiles) => {
    setFileList(currentState => {
      return currentState.filter(item => item.name !== data.name);
    });
  };

  return {
    items: fileList,
    upsert,
    remove,
  };
};
