const RoutesPath = {
  ALL: '*',
  ROOT: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  SELECT_WORKSPACE: '/select-workspace',
  RESET_PASSWORD: '/reset-password',
  CREATE_PASSWORD: '/create-password',
  BRAND_LIBRARY: '/brand-library',
  MANAGE_TEAM: '/manage-team',
};

export default RoutesPath;
