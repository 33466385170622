import { FC } from 'react';

import { PrimaryTick } from '@assets/icons';
import { Button } from '@components/index';

interface SuccessMessageTemplate {
  heading: string;
  subHeading?: string;
  buttonLabel?: string;
  onButtonClick?: () => void;
  IconComponent?: React.FunctionComponent<React.SVGProps<SVGSVGElement>> | null;
}

const SuccessMessageTemplate: FC<SuccessMessageTemplate> = ({
  heading,
  subHeading,
  buttonLabel,
  onButtonClick = () => {},
  IconComponent = PrimaryTick,
}) => {
  return (
    <div className="h-screen w-screen flex justify-center items-center">
      <div className="w-1/2">
        <div className="flex flex-col items-center mx-auto max-w-[476px] p-4 gap-8">
          <IconComponent className="mx-auto" />
          <div className="flex flex-col items-center gap-2">
            <div className="font-montserrat text-2xl font-bold">{heading}</div>
            <div className="text-sm text-center">{subHeading}</div>
          </div>
          {buttonLabel && (
            <Button
              variant="contained"
              label={buttonLabel}
              onClick={onButtonClick}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SuccessMessageTemplate;
