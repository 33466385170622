import { atom } from 'recoil';

import { User } from '@hooks/types';

import { Atoms } from '../constants';

export const userProfile = atom({
  key: Atoms.userProfile,
  default: {} as User,
});
