import { FC } from 'react';

export interface ProgressBarProps {
  progress: number;
}

const ProgressBar: FC<ProgressBarProps> = ({ progress = 0 }) => {
  return (
    <div className="relative w-full">
      <div className="overflow-hidden h-1 rounded text-sm flex bg-lighter">
        <div
          className="bg-primary flex flex-col transition-all ease duration-200"
          style={{ width: `${progress}%` }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
