import { atom } from 'recoil';

import { CustomerWhiteLabel } from '@hooks/types';

import { Atoms } from '../constants';

export const customer = atom({
  key: Atoms.customer,
  default: {} as CustomerWhiteLabel,
});
