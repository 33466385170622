import axios, { AxiosRequestConfig } from 'axios';
import { isNil } from 'lodash';
import { stringify } from 'qs';

import { getErrorMessage } from '@utils/error';

import { HttpMethod } from './enums';

const paramsSerializer = (params: any) =>
  stringify(params, { arrayFormat: 'repeat' });

interface createHttpClientProps {
  allowWithCredentials?: boolean;
  baseApiUrl?: string;
}

export const createHttpClient = (props: createHttpClientProps = {}) => {
  const {
    allowWithCredentials = true,
    baseApiUrl = "https://api.stg.brandtechbrain.com/api/",
  } = props;
  const instance = axios.create({ withCredentials: allowWithCredentials });

  return async <R>({
    url,
    method = HttpMethod.GET,
    params,
    data,
    onUploadProgress,
  }: AxiosRequestConfig) => {
    try {
      const response = await instance.request<R>({
        method,
        baseURL: baseApiUrl,
        url,
        data,
        params,
        paramsSerializer,
        onUploadProgress,
      });

      return response.data as R;
    } catch (error: any) {
      const msg = isNil(error?.response)
        ? error.message
        : getErrorMessage(error);

      throw new Error(msg);
    }
  };
};
