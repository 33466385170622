export const QUERY_KEYS = {
  USER: {
    GET_USER_DETAILS: 'GET_USER_DETAILS',
    USER_LIST: 'USER_LIST',
  },
  WORKSPACE: {
    SELECT_WORKSPACE_LIST: 'SELECT_WORKSPACE_LIST',
    SELECT_WORKSPACE: 'SELECT_WORKSPACE',
    ALL_WORKSPACES: 'ALL_WORKSPACES',
    CLIENT_HIERARCHY: 'CLIENT_HIERARCHY',
    GET_CURRENT_WORKSPACE: 'GET_CURRENT_WORKSPACE',
  },
  BRAND_LIBRARY: {
    BRAND_ASSETS_LIST: 'BRAND_ASSETS_LIST',
  },
  ROLES: {
    ROLE_LIST: 'ROLE_LIST',
  },
  KNOWLEDGE_ATTRIBUTE: {
    KNOWLEDGE_ATTRIBUTE_LIST: 'KNOWLEDGE_ATTRIBUTE_LIST',
  },
  HIERARCHY: {
    ASSETS_HIERARCHY: 'ASSETS_HIERARCHY',
  },
  CUSTOMER: {
    GET_CUSTOMER: 'GET_CUSTOMER',
  },
};
