import { useMutation } from 'react-query';
import { useRecoilValue } from 'recoil';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import RoutesPath from '@routes/constants';
import { workspace } from '@store/atoms/workspace';
import { LocalStorageKeys } from '@utils/auth';

import { usePusher } from './use-pusher';

export const useLogout = () => {
  const currentWorkspace = useRecoilValue(workspace);
  const { unsubscribeChannel } = usePusher(currentWorkspace.id);

  const client = createHttpClient();

  const mutation = useMutation(
    () =>
      client({
        method: HttpMethod.POST,
        url: END_POINTS.AUTH.LOGOUT,
      }),
    {
      onSuccess: () => {
        unsubscribeChannel();
        localStorage.removeItem(LocalStorageKeys.IS_LOGGED_IN);
        window.history.pushState(
          null,
          '',
          window.location.origin + RoutesPath.LOGIN,
        );
        window.location.href = window.location.origin + RoutesPath.LOGIN;
      },
      onError: () => {
        showAlert({
          type: AlertType.ERROR,
          message: 'Failed to logout',
        });
      },
    },
  );

  return mutation;
};
