import { useMutation } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

import { CreateWorkspaceData, CreateWorkspaceResponse } from '../types';

export const useCreateWorkspace = () => {
  const client = createHttpClient();

  const mutation = useMutation(
    (payload: CreateWorkspaceData) =>
      client<CreateWorkspaceResponse>({
        url: END_POINTS.WORKSPACE.BASE_WORKSPACE,
        method: HttpMethod.POST,
        data: payload,
      }),
    {
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to create workspace',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return mutation;
};
