import { FC } from 'react';

import { AngleForward, Check } from '@assets/icons';
import { Button } from '@components/index';

export interface WorkspaceOptionItemProps {
  option: any;
  isSelected?: boolean;
  onNextClick?(e: any): void;
  classOverride?: string;
}

const CLIENT_ID_WITHOUT_PARENT = 0;

const WorkspaceOptionItem: FC<WorkspaceOptionItemProps> = ({
  option,
  isSelected = false,
  onNextClick = () => null,
  classOverride = '',
}) => {
  const isNoParentClientOption = option.clientId === CLIENT_ID_WITHOUT_PARENT;

  return (
    <span className="flex items-center justify-between cursor-pointer focus:select-none pl-4 h-[45px] border-b border-gray-2 hover:bg-lightest pr-2">
      <span className={`text-sm block truncate flex-grow ${classOverride}`}>
        {option.name}
      </span>
      <div className="flex items-center">
        <Check
          className={`flex-none text-primary ${
            isSelected ? '' : 'invisible'
          } ${isNoParentClientOption ? 'mx-3' : ''}`}
        />
        {!isNoParentClientOption && (
          <Button
            variant="text"
            label=""
            IconRightComponent={AngleForward}
            classOverride="!h-4 !w-4 !text-gray-1"
            onClick={e => onNextClick(e)}
          />
        )}
      </div>
    </span>
  );
};

export default WorkspaceOptionItem;
