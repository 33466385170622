import Spinner from '@components/spinner/Spinner';

const PageFallback = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center">
      <Spinner />
    </div>
  );
};

export default PageFallback;
