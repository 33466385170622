import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';

import { BrandDnaiLogo, Message, QuestionMark } from '@assets/icons';
import SwitchWorkspaceForm from '@pages/select-workspace/components/SwitchWorkspaceForm';
import RoutesPath from '@routes/constants';
import { customer } from '@store/atoms/customer';

import SettingsOption from './settings-option/SettingsOption';

const SideNav = () => {
  const customerData = useRecoilValue(customer);

  return (
    <div className="w-[185px] min-w-[185px] h-screen flex flex-col justify-between pt-2 pb-5 border-r border-gray-6">
      <div className="px-2">
        <Link to={RoutesPath.BRAND_LIBRARY}>
          {customerData?.metadata?.logoUrl ? (
            <img
              src={customerData.metadata.logoUrl}
              alt="Brand-DNAI logo"
              className="h-12 w-[150px] mx-auto"
            />
          ) : (
            <BrandDnaiLogo className="h-9 w-[150px] mx-auto" />
          )}
        </Link>
        <div className="mt-6 mb-4 font-bold text-xs text-gray-5">WORKSPACE</div>
        <SwitchWorkspaceForm />
      </div>
      <div className="flex flex-col gap-4 ml-8">
        <SettingsOption />
        <div className="flex flex-row gap-2">
          <QuestionMark />
          <span className="text-sm font-semibold">Get help</span>
        </div>
        <div className="flex flex-row gap-2">
          <Message />
          <span className="text-sm font-semibold">What's new</span>
        </div>
      </div>
    </div>
  );
};

export default SideNav;
