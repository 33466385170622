import { ChangeEvent, FC, forwardRef } from 'react';

export interface TextAreaProps {
  value?: string;
  onChange?(e: ChangeEvent<HTMLTextAreaElement>): void;
  isResizable?: boolean;
  name?: string;
  placeholder?: string;
  register?: any;
  rows?: number | undefined;
  isDisabled?: boolean;
  classOverride?: string;
}

const TextAreaComponent: FC<TextAreaProps> = ({
  value,
  isResizable = false,
  onChange = () => null,
  name = '',
  placeholder = '',
  register,
  rows,
  isDisabled = false,
  classOverride = '',
}) => {
  const onChangeText = (e: ChangeEvent<HTMLTextAreaElement>) => {
    onChange(e);
  };

  return (
    <div className="h-full flex flex-col items-end relative">
      <textarea
        ref={register}
        value={value}
        className={
          'w-full h-full focus:outline-none border px-4 py-3 rounded ' +
          (!isResizable ? 'resize-none ' : '') +
          (classOverride || '')
        }
        name={name}
        placeholder={placeholder}
        rows={rows}
        disabled={isDisabled}
        onChange={onChangeText}
      />
    </div>
  );
};

const TextArea = forwardRef<typeof TextAreaComponent, TextAreaProps>(
  (props, ref) => <TextAreaComponent {...props} register={ref} />,
);

export default TextArea;
