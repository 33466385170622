import { FC } from 'react';

import { AngleForward, Ellipsis } from '@assets/icons';
import Button from '@components/button/Button';

const BreadCrumb = ({
  label,
  isActive = false,
  onClick = () => {},
}: {
  label: string;
  isActive?: boolean;
  onClick?: () => void;
}) => {
  return (
    <div className="flex space-x-2">
      <AngleForward className="h-4 w-4 text-gray-1" />
      <Button
        variant="text"
        label={label}
        classOverride={`max-w-[120px] truncate ${
          isActive ? 'text-lightest' : '!text-black hover:opacity-70'
        }`}
        onClick={onClick}
      />
    </div>
  );
};

export interface BreadCrumbsProps {
  idPath: string;
  namePath: string;
  onOptionClick: (id: number | null) => void;
}

const BreadCrumbs: FC<BreadCrumbsProps> = ({
  idPath,
  namePath,
  onOptionClick,
}) => {
  const optionIds = idPath?.split('/').filter(Boolean);
  const optionNames = namePath?.split('/').filter(Boolean);
  const noOfOptions = optionIds?.length || 0;

  return (
    <div className="h-10 flex items-center space-x-2 px-4">
      <Button
        variant="text"
        label={noOfOptions <= 2 ? 'All' : ''}
        classOverride="!text-black hover:opacity-70"
        onClick={() => onOptionClick(null)}
        IconLeftComponent={noOfOptions > 2 ? Ellipsis : null}
      />
      {noOfOptions > 1 && (
        <BreadCrumb
          label={optionNames[noOfOptions - 2]}
          onClick={() => onOptionClick(Number(optionIds[noOfOptions - 2]))}
        />
      )}
      <BreadCrumb
        label={optionNames[noOfOptions - 1]}
        isActive={true}
        {...(noOfOptions === 1 && { className: 'max-w-[240px]' })}
      />
    </div>
  );
};

export default BreadCrumbs;
