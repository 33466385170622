import { atom } from 'recoil';

import { Atoms } from '@store/constants';

export type UploadFiles = {
  name: string;
  progressPercentage: number;
};

export const uploadFiles = atom({
  key: Atoms.uploadFiles,
  default: [] as UploadFiles[],
});
