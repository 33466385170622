import { FC, ReactNode, useCallback } from 'react';

import { useDropzone } from 'react-dropzone';

interface StyledDropzoneProps {
  accept?: string;
  children?: ReactNode;
  maxSize?: number;
  multiple?: boolean;
  onFilesReject?: any;
  onFilesDrop?(files: File[]): void;
}

const StyledDropzone: FC<StyledDropzoneProps> = ({
  accept = '',
  children,
  maxSize,
  multiple = true,
  onFilesDrop = () => null,
  onFilesReject = () => null,
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => onFilesDrop(acceptedFiles),
    [onFilesDrop],
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    multiple,
    onDrop,
    ...(onFilesReject && { onDropRejected: onFilesReject }),
    ...(maxSize && { maxSize }),
    noDragEventsBubbling: true,
  });

  const handleEvent = (e: React.SyntheticEvent) => {
    e.stopPropagation();
  };

  return (
    <div
      className={`relative w-full h-full border ${
        isDragActive ? 'border-gray-4 border-dashed' : 'border-transparent'
      }`}
      {...getRootProps()}
    >
      {isDragActive && (
        <div className="absolute inset-0 z-10 flex justify-center items-center">
          <div className="absolute inset-0 bg-gray-7 opacity-12"></div>
          <div className=" absolute flex justify-center items-center top-96">
            <div className="w-max text-dark-1 text-xl font-semibold">
              Drop your file here to upload
            </div>
          </div>
        </div>
      )}
      <div className={'w-full h-full'}>
        <div
          className={`pb-10 ${isDragActive ? 'blur-sm' : ''}`}
          onClick={handleEvent}
          onKeyDown={handleEvent}
          onDrop={handleEvent}
        >
          {children}
        </div>
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

export default StyledDropzone;
