import { useQuery } from 'react-query';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

export const useSelectWorkspace = ({
  userId,
  clientUuid,
  enabled,
  onSuccess,
  onError,
}: {
  userId: number | null;
  clientUuid: string;
  enabled: boolean;
  onSuccess: () => void;
  onError?: () => void;
}) => {
  const client = createHttpClient();

  const query = useQuery(
    [QUERY_KEYS.WORKSPACE.SELECT_WORKSPACE, userId, clientUuid],
    () =>
      client({
        url: END_POINTS.WORKSPACE.SELECT_CLIENT(userId, clientUuid),
      }),
    {
      enabled,
      onSuccess: onSuccess,
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to select workspace',
          message: `${error?.message || 'Request failed'}`,
        });

        if (onError) {
          onError();
        }
      },
    },
  );

  return query;
};
